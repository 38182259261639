import logo from './logo.svg';
import './App.css';
import NorthStarThemeProvider from 'aws-northstar/components/NorthStarThemeProvider';
import Grid from 'aws-northstar/layouts/Grid';
import Placeholder from 'aws-northstar/components/Placeholder';
import Container from 'aws-northstar/layouts/Container';
import background from './img/Summit Background.jpg';
import Header from 'aws-northstar/components/Header'
import Button from 'aws-northstar/components/Button';
import { useEffect, useState, ReactDOM } from 'react';
import VoiceChat from '@material-ui/icons/VoiceChat';
import ContactPhone from '@material-ui/icons/ContactPhone';
import ConnectLogo from './img/ConnectLogo.png';
import NFCLogo from './img/NFC.svg';
import axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import {
  MeetingProvider,
  lightTheme,
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import MyApp from './components/ChimeSDK';
import { genLogger } from "./lib/logger";
import { loggerNames } from './constants';
import { AppConfigProvider } from './providers/AppConfigProvider';
import parse from 'html-react-parser';

const name = loggerNames.APP;
const { log } = genLogger(name);

const App = () => {
  let jwt;
  const autoStartIoTReader = () => {
    const jwtGet = 'https://m1miymvswk.execute-api.eu-central-1.amazonaws.com/dev';
      var token = axios.get(jwtGet);
      token.then((data) => {
        console.log('Token received: ' + JSON.stringify(data.data.authToken));
        jwt = data.data.authToken;
        const is = new WebSocket("wss://n8nn1iz4mi.execute-api.eu-central-1.amazonaws.com/dev", ["websocket",jwt]); 
        //console.log(ws);
        const IoTCall = {
          "action": "subscribe",
          "entityId": "IoTUpdates"
        }
        
        is.onopen = (event) => {
          console.log(JSON.stringify(event));
          console.log('WebSocket connected to ' + is.url);
          is.send(JSON.stringify(IoTCall));
        };
    
        is.onerror = (error) => {
          is.close();
          autoStartIoTReader();
          window.location.reload(false);
          const jwtGet = 'https://m1miymvswk.execute-api.eu-central-1.amazonaws.com/dev';
          var token = axios.get(jwtGet);
          token.then((data) => {
            console.log('Token received: ' + JSON.stringify(data.data.authToken));
            jwt = data.data.authToken;
            //console.log(ws);
          })
          .catch((e) => {
            console.log('Error on token request!');
            console.log(e);
          })
          console.log(token);
          console.log('Error on WebSocket!');
          console.log(error);
        }
    
        is.onmessage = function (event) {
          console.log(event);
          const json = JSON.parse(event.data);
          console.log(json);
          console.log('[MESSAGE] Data Received from server: ' + JSON.stringify(json));
          if(json.entityId == 'IoTUpdates') {
            console.log('[MESSAGE] - IoT Hook Message received.');
            onClickVideo();
          }
          
        }
      })
    
    
    console.log('Initial Page load!');
  }

  autoStartIoTReader();
  const [nfcGrid, setNFCGrid] = useState('createNFC');
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  const [videChatGrid, setChimeGrid] = useState('chimeGridNoShow');
  const [introDescription, setDescription] = useState('introDescriptionShow');
  const [descriptionText, setDescriptionText] = useState('Did you know that there are multiple ways to interact with Customer Service not only Phone and Chat? <br /><br />Press the <b>IoT button </b>in front of you to start the Amazon Connect Video Chat experience or click on the Button. <br /><br /><i>IoT stands for Internet of Things -  a system of unrelated computing devices, mechanical and digital machines, objects and many more.</i>' ) 
  //const [showLeft, setShowLeft] = React.useState(true);
  
  function waitForEndSession() {
    const jwtGet = 'https://m1miymvswk.execute-api.eu-central-1.amazonaws.com/dev';
    var token = axios.get(jwtGet);
    const ws = new WebSocket("wss://n8nn1iz4mi.execute-api.eu-central-1.amazonaws.com/dev", ["websocket",jwt]);
    const waitforend = {
      "action": "subscribe",
      "entityId": "endChime"
    }
    ws.onopen = (event) => {
      console.log(JSON.stringify(event));
      console.log('WebSocket connected to ' + ws.url);
      ws.send(JSON.stringify(waitforend));
    };

    ws.onmessage = function (event) {
      console.log(event);
      const json = JSON.parse(event.data);
      console.log(json);
      console.log('[MESSAGE] Data Received from server: ' + JSON.stringify(json));
      if(json.entityId == 'endChime') {

        console.log('End Session received');
        window.location.reload(false);
      }
      
    }

    ws.onerror = function() {
      waitForEndSession();
    }

  }
  
  function onClickVideo() {
    
    setDescription('introDescriptionShowNoShow');
    setLoading(false);
    setNFCGrid('createNFC');
    setChimeGrid('chimeGridShow');
    waitForEndSession();
      const jwtGet = 'https://m1miymvswk.execute-api.eu-central-1.amazonaws.com/dev';
      var token = axios.get(jwtGet);
      //setDescriptionText('You can use Website Metadata to gather Information. <br />In this Demo we use Information from your AWS Summit Ticket to get some Information about you');
      setDescriptionText('');
      token.then((data) => {
        console.log('Token received: ' + JSON.stringify(data.data.authToken));
        jwt = data.data.authToken;
        const ws = new WebSocket("wss://n8nn1iz4mi.execute-api.eu-central-1.amazonaws.com/dev", ["websocket",jwt]); 
        //console.log(ws);
        const apiCall = {
          "action": "subscribe",
          "entityId": "NFCUpdates"
        };
        const IoTCall = {
          "action": "subscribe",
          "entityId": "IoTUpdates"
        }
        
        ws.onopen = (event) => {
          console.log(JSON.stringify(event));
          console.log('WebSocket connected to ' + ws.url);
          ws.send(JSON.stringify(apiCall));
          ws.send(JSON.stringify(IoTCall));
        };
    
        ws.onerror = (error) => {
          ws.close();
          const jwtGet = 'https://m1miymvswk.execute-api.eu-central-1.amazonaws.com/dev';
          var token = axios.get(jwtGet);
          token.then((data) => {
            console.log('Token received: ' + JSON.stringify(data.data.authToken));
            jwt = data.data.authToken;
            //console.log(ws);
          })
          .catch((e) => {
            console.log('Error on token request!');
            console.log(e);
          })
          console.log(token);
          console.log('Error on WebSocket!');
          console.log(error);
        }
    
        ws.onmessage = function (event) {
          console.log(event);
          const json = JSON.parse(event.data);
          console.log(json);
          console.log('[MESSAGE] Data Received from server: ' + JSON.stringify(json));
          if(json.entityId == 'NFCUpdates') {

            console.log('[MESSAGE] - NFC Reader Message received.');
            setDescription('introDescriptionShowNoShow');
            setLoading(false);
            setNFCGrid('createNFC');
            setChimeGrid('chimeGridShow');
            waitForEndSession();
          }
          
        }
      })
    
    
    console.log('Video Experience selected!');
    setLeftGrid('innerGridLeftNoShow');
    setRightGrid('innerGridRightNoShow');
    setNFCGrid('createNFCShow');
  }

  function onClickCall() {
    console.log('Call Experience selected');
    setRightGrid('innerGridRightNoShow');
    setLeftGrid('innerGridLeftNoShow');
  }



  const [leftGrid, setLeftGrid] = useState('innerGridLeft')
  const [rightGrid, setRightGrid] = useState('innerGridRight')
  

  return (
  <NorthStarThemeProvider>
              <Grid container spacing={0} className='mainGrid'>
                <Grid container spacing={0} className='upperGrid'> 
                  <Header title='Amazon Connect Video Chat Experience' logoPath={ConnectLogo}></Header>
                  <Grid item xs={2} className={introDescription}></Grid>
                  <Grid item xs={8} className={introDescription} id='descriptionText'>
                    {parse(descriptionText)}
                  </Grid>
                  <Grid item xs={2} className={introDescription}></Grid>
                  <Grid item xs={12} className={leftGrid}>
                    <Button icon={VoiceChat} iconAlign="left" size='large' type='submit' variant='primary' onClick={onClickVideo}>Video Chat Experience</Button>
                  </Grid>
                  <Grid item xs={2}className={videChatGrid}></Grid>
                  <Grid item xs={8} className={videChatGrid}>
                    <ThemeProvider theme={lightTheme}>
                      <MeetingProvider>
                        Please click on the Chat button on the bottom right.
                        <MyApp />
                      </MeetingProvider>
                    </ThemeProvider>
                  </Grid>
                  <Grid item xs={2}className={videChatGrid}></Grid>
                  
                  <Grid item xs={12} className={nfcGrid}>
                    <div className="nfcTextBot">
                    
                    </div>
                    <br></br>
                    <ClipLoader color={color} loading={loading} size={150} />
                  </Grid>
                  
                </Grid>
                <Grid className='lowerGrid'>
                  
                </Grid>
              </Grid>
  </NorthStarThemeProvider>
  )
};

export default App;